import { doQuery } from "@/api/core"
import {mapActions, mapGetters} from 'vuex'
import CmyPage from '@/components/cmy-page/cmy-page.vue'
import { searchByMulti } from "@/api/lesson";
import ErrorInfoDrawer from '@/components/error-info-drawer/error-info-drawer'

export default {
    name: "tools",

    computed: {
        ...mapGetters([
            'winSize',
        ])
    },

    components: {
        CmyPage,
        ErrorInfoDrawer
    },

    mounted() {

        //如果包含在电子手册里
        if(this.tool_type_list.includes(this.$route.query.type)) {

            this.manualType = this.tool_type_list.findIndex(o=>o==this.$route.query.type)
            console.log(this.manualType)
            this.showTaxOperationManualModal()

        }

        else if(this.$route.query.type == 'question_analyze' || this.$route.query.type == 'tags_types_guide') {

            this.showQuestionAnalyzeModal(this.$route.query.type)

        }
    },

    data() {
        return {

            //手册类型
            manualType: '0',

            tool_type_list: ['tax_operation_manual','app_operation_manual','ptax_koujiao_manual','tax_operation_manual_new'],
            // b 税务操作手册 =======================================================
            tax_operation_manual: [],
            tax_operation_manual_modal: false,
            tax_operation_manual_pagination: {
                page: 1,
                limit: 60,
                total:0,
                end: false
            },
            tax_operation_manual_keywords: undefined,
            manual_types:[],

            tax_operation_manual_detail: {
                title: undefined,
                content: undefined
            },
            tax_operation_manual_detail_modal: false,
            // e 税务操作手册 =======================================================

            // b 深度问题分析 =======================================================
            question_analyze_modal: false,
            question_analyze_keywords: undefined,
            kdbtype_selected_id: '%',
            kdbtype_list: [],
            question_analyze_pagination: {
                page: 1,
                limit: 20,
                total:0,
                end: false
            },
            question_analyze_list: [],

            question_analyze_detail_modal: false,
            question_analyze_detail: {
                title: undefined,
                content: undefined,
            },

            modalStyle:{
                'padding':'10px',
                'height': (this.$store.getters.winSize[1] - 300) + 'px',
                'background-color': 'rgb(255, 255, 255)',
                'overflow-y': 'auto',
                'overflow-x': 'visible'
            },
            modalWidth:'1000px',
            modalIcon:'fullscreen',
            // e 深度问题分析 =======================================================

            kidSelectPrepare: null,
            kidSelect: null,
        }
    },

    methods: {
        ...mapActions([
            'GetPkdbtypeByKdbtypeCode',
            'GetTxttypeByKdbtypeCode'
        ]),

        onFullScreen(e){

            if (this.modalWidth == '1000px') {
                this.$set(this.modalStyle, "height", this.$store.getters.winSize[1] + "px");
                this.modalWidth = '100%';
                this.modalIcon = 'fullscreen-exit';
            }
            else {
                this.$set(this.modalStyle, "height", (this.$store.getters.winSize[1] - 300) + "px");
                this.modalWidth = '1000px';
                this.modalIcon = 'fullscreen';
            }
        },

        onTapManualType(item){
            this.searchTaxOperationManual(item);
        },

        // b 税务操作手册 =======================================================
        queryTaxOperationManual(page = this.tax_operation_manual_pagination.page, limit= this.tax_operation_manual_pagination.limit) {
            const that = this

            //add total
            doQuery({
                query_code: 'LNT110',
                param_str1: that.tax_operation_manual_keywords,
                param_str2: that.manualType,
                page,
                limit
            }).then(res => {
                if (res.errcode >= that.$consts.biz.OK && res.data) {
                    that.tax_operation_manual_pagination.total = res.data[0].total
                }
            })

            doQuery({
                query_code: 'LNT100',
                param_str1: that.tax_operation_manual_keywords,
                param_str2: that.manualType,
                page,
                limit
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    that.tax_operation_manual = res.data

                    doQuery({
                        query_code: 'LNT10X',
                        param_str2: that.manualType,
                        page,
                        limit
                    }).then(res2 => {
                        if(res2.errcode === that.$consts.biz.OK && res2.data) {
                            that.manual_types = res2.data;
                        }
                        else {
                            //扫描形成types，方便过滤
                            res.data.forEach(function(item,index){
                                let v_title_type = item.title.slice(0,item.title.indexOf('-'));
                                if  (v_title_type && v_title_type != '' && that.manual_types.indexOf(v_title_type) < 0) {
                                    that.manual_types.push({name:v_title_type})
                                }
                            })
                        }
                    })

                }
            })
        },

        searchTaxOperationManual(value) {
            const that = this
            that.tax_operation_manual_keywords = value
            that.tax_operation_manual_pagination.page = 1
            that.queryTaxOperationManual()
        },

        showTaxOperationManualModal() {
            const that = this
            that.tax_operation_manual_keywords = undefined
            that.tax_operation_manual =[]
            that.tax_operation_manual_pagination.page = 1
            that.queryTaxOperationManual()
        },

        showManualDetail(index) {
            const that = this
            that.kidSelectPrepare = that.tax_operation_manual[index].id
            that.tax_operation_manual_detail = {
                title: undefined,
                content: undefined
            }
            doQuery({
                query_code: 'LNT101',
                param_str1: that.tax_operation_manual[index].id
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    that.tax_operation_manual_detail = res.data[0]
                    that.tax_operation_manual_detail.title = that.tax_operation_manual[index].title
                    that.tax_operation_manual_detail_modal = true
                }
            })
        },
        // e 税务操作手册 =======================================================

        showSubmitErrorModal() {
            const that = this
            that.kidSelect = that.kidSelectPrepare
        },
        onTaxOperationManualDetailModalClose() {
            const that = this
            that.tax_operation_manual_detail_modal = false
            that.kidSelectPrepare = null
            that.kidSelect = null
            that.$refs['errorInfoDrawer'].clearErrorInfo()
        },
        onQuestionAnalyzeDetailModalClose() {
            const that = this
            that.question_analyze_detail_modal = false
            that.kidSelectPrepare = null
            that.kidSelect = null
            that.$refs['errorInfoDrawer'].clearErrorInfo()
        },
        // b 深度问题分析 =======================================================

        showQuestionAnalyzeModal(type_name='question_analyze') {
            const that = this
            that.question_analyze_keywords = undefined
            doQuery({
                query_code: (type_name == 'question_analyze' ? 'LNT200':'LNT220'),
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    that.kdbtype_list = [{kdbtype: '全部类别',kdbtype_code: '%'},...res.data]
                    that.kdbtype_selected_id = that.kdbtype_list[0].kdbtype_code
                    that.question_analyze_pagination.page = 1
                    that.queryQuestionAnalyze(1,that.question_analyze_pagination.limit,type_name)
                }
            })
        },

        searchQuestionAnalyze(value) {
            const that = this
            that.question_analyze_keywords = value
            that.question_analyze_pagination.page = 1
            that.queryQuestionAnalyze(1,that.question_analyze_pagination.limit,this.$route.query.type)
        },

        queryQuestionAnalyze(page = this.question_analyze_pagination.page, limit= this.question_analyze_pagination.limit,type_name='question_analyze') {
            const that = this
            that.question_analyze_list = []

            if(!that.question_analyze_keywords) {

                //add total
                doQuery({
                    query_code: (type_name=='question_analyze'?'LNT21X':'LNT23X'),
                    param_str1: that.kdbtype_selected_id,
                    page,
                    limit
                }).then(res => {
                    if (res.errcode >= that.$consts.biz.OK && res.data) {
                        that.question_analyze_pagination.total = res.data[0].total

                    }
                })

                doQuery({
                    query_code: (type_name=='question_analyze'?'LNT210':'LNT230'),
                    param_str1: that.kdbtype_selected_id,
                    page,
                    limit
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data) {
                        that.question_analyze_list = res.data
                    }
                })
            }else {
                var arr = []
                that.kdbtype_list.forEach(item => {
                    arr.push(item.kdbtype_code)
                })
                var kdbtype_code_arr = (that.kdbtype_selected_id && that.kdbtype_selected_id != '' && that.kdbtype_selected_id != '%') ? [that.kdbtype_selected_id] : arr
                searchByMulti({
                    keywords: that.$cmyUtils.clearTxt(that.question_analyze_keywords),
                    matchType: '2',
                    basis: '',
                    endTime: '',
                    isInvalid: '',
                    kdbtypeCodeArr: kdbtype_code_arr,
                    refSrc: '',
                    startTime: '',
                    sortField:'',
                    page: page,
                    limit: limit
                }).then(res => {
                    if(res.errcode != '0' || !res.data) {
                        that.$message.error('搜索发生异常，请稍后再试')
                        that.question_analyze_pagination.end = true
                        return
                    }

                    var doc = res.data.searchList //如果没有搜索到结果
                    that.question_analyze_pagination.total= res.data.totalCount<=1000?res.data.totalCount:1000
                    console.log(that.question_analyze_pagination.total)

                    if (!doc || doc.length == 0) {
                        that.question_analyze_list = []
                    }else {
                        doc.forEach(function(item, index) {
                            that.setSearchItem(item)
                        })
                        that.question_analyze_list = doc
                    }
                })
            }
        },

        /**
         * 对搜索结果的item进行特别处理
         */
        setSearchItem: function(item) {
            var that = this
            //结果加红标记处理
            if (item.title) {
                item.title = item.title.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.title = item.title.replace(/<\/font>/g, '</a>')
            }
            if (item.summary) {
                item.summary = item.summary.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.summary = item.summary.replace(/<\/font>/g, '</a>')
                item.summary = '...' + item.summary + '...'
            }
            if (item.refSrc) {
                item.refSrc = item.refSrc.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.refSrc = item.refSrc.replace(/<\/font>/g, '</a>')
            }
            if (item.basis) {
                item.basis = item.basis.replace(/<font color='red'>/g, '<a style="color:red;">')
                item.basis = item.basis.replace(/<\/font>/g, '</a>')
            }
            //增加父类型、有效状态的标记在最前面
            Promise.all([
                that.GetPkdbtypeByKdbtypeCode(item.kdbtypeCode)
                    .then(res => {
                        item.pkdbtype = res
                    }),
                that.GetTxttypeByKdbtypeCode(item.kdbtypeCode)
                    .then(res => {
                        item.txttype = res
                    })
            ]).then(() => {
                //如果不是法规，表示参考法规
                if (item.txttype && item.txttype != '' && item.txttype.indexOf('法规') < 0) {
                    if (item.basis && item.basis != '') {
                        item.basis = '【参考法规】' + item.basis
                    }
                }
                if (item.isInvalid == '0') {
                    item.title =
                        '<a style="background-color:#e93d52;color:white;font-size:12px;height:17px;line-height:17px;padding: 0 2px;margin-right:2px;border-radius:2px;">全文失效</a>' +
                        item.title
                }
                if (item.isInvalid == '2') {
                    item.title =
                        '<a style="background-color:#f56400;color:white;font-size:12px;height:17px;line-height:17px;padding: 0 2px;margin-right:2px;border-radius:2px;">条款失效</a>' +
                        item.title
                }
            })

        },

        handleKdbTypeMenuClick({ item, key, keyPath }) {
            const that = this
            that.kdbtype_selected_id = key
            that.question_analyze_pagination.page = 1
            that.queryQuestionAnalyze(1,that.question_analyze_pagination.limit,that.$route.query.type)
        },

        showQuestionAnalyzeDetailModal(index) {
            const that = this
            that.kidSelectPrepare = that.question_analyze_list[index].id
            doQuery({
                query_code: 'LNT101',
                param_str1: that.question_analyze_list[index].id
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    that.question_analyze_detail = res.data[0]
                    that.question_analyze_detail.title = that.question_analyze_list[index].title
                    that.question_analyze_detail_modal = true
                }
            })
        }
        // e 深度问题分析 =======================================================
    }
}
